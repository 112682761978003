import React from "react";
import Image from "../elements/Image";
import DesignButton from "../extra/DesignButton";

const ProductCard = ({ data }) => {
  return (
    <div className="bg-white py-8 mb-8 rounded-lg shadow-md">
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row">
          <div className="md:flex-1 px-4">
            <div className="h-[300px] md:h-[460px] rounded-lg bg-gray-300 dark:bg-gray-700 mb-4 overflow-hidden">
              <Image
                src={data?.image}
                alt={data?.title}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="md:flex-1 px-4 mt-4 md:mt-0 flex flex-col justify-between">
            <div>
              <h2 className="text-2xl md:text-4xl font-bold text-gray-800 dark:text-victoria mb-2">
                {data?.title}
              </h2>
              <p className="text-gray-600 dark:text-gray-700 text-base md:text-lg mt-4">
                {data?.description}
              </p>
              <div className="w-full mt-8">
                <DesignButton
                  buttonLabel={data?.btnText}
                  buttonLabelClasses={"!text-base md:!text-lg"}
                  buttonClasses={
                    "!px-4 md:!px-7 !py-3 md:!py-6 hover:!bg-black transition-all duration-300 !text-sm md:!text-base !bg-victoria"
                  }
                  buttonEffect="filled"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
