import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Button from "../form/Button";
import ServiceItem from "./ServiceItem";

const ServiceList = ({
  categoryData,
  routeName,
  selectedCategoryId,
  setSelectedCategoryId = () => {},
  slidesPerView = 9.5,
}) => {

  useEffect(() => {
    const prevButton = document.querySelector(".categorySwiper-prev");
    const nextButton = document.querySelector(".categorySwiper-next");

    if (prevButton) prevButton.classList.remove("swiper-button-disabled");
    if (nextButton) nextButton.classList.remove("swiper-button-disabled");
  }, []);

  const handleSelectCategory = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  return (
    <>
      <div className="relative w-full px-11">
        <Swiper
          spaceBetween={10}
          navigation={{
            nextEl: ".categorySwiper-next",
            prevEl: ".categorySwiper-prev",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1.5,
            },
            480: {
              slidesPerView: 2.5,
            },
            640: {
              slidesPerView: 3.5,
            },
            768: {
              slidesPerView: 4.5,
            },
            1024: {
              slidesPerView: 6.5,
            },
            1280: {
              slidesPerView: slidesPerView,
            },
          }}
          modules={[Navigation]}
          autoplay={false}
          className="category-swiper"
        >
          {categoryData &&
            Array.isArray(categoryData) &&
            categoryData.map((item, i) => (
              <SwiperSlide key={i?.toString()} className="!w-fit">
                <ServiceItem
                  data={item}
                  onSelect={handleSelectCategory}
                  isSelected={selectedCategoryId === item?.code}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="relative w-full z-[5]">
          <div className="absolute -top-[42px] -left-11">
            <Button
              buttonIcon={"fa-regular fa-chevron-left"}
              buttonIconPosition={"left"}
              buttonClasses={"categorySwiper-prev"}
            />
          </div>
          <div className="absolute -top-[42px] -right-11">
            <Button
              buttonIcon={"fa-regular fa-chevron-right"}
              buttonIconPosition={"left"}
              buttonClasses={"categorySwiper-next"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceList;
