import React, { useEffect, useState } from "react";
import Image from "../components/elements/Image";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useDispatch } from "react-redux";

const DefaultHeader = ({ data, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuData = [
    { _id: 1, name: "Home", link: "/" },
    { _id: 2, name: "Services", link: "/service" },
    { _id: 3, name: "Products", link: "/product" },
    { _id: 4, name: "Book Now", link: "/" },
    { _id: 5, name: "About Us", link: "/about" },
    { _id: 6, name: "Contact", link: "/contact" },
  ];

  const socialMenu = [
    {
      _id: 1,
      name: "Facebook",
      link: "https://www.facebook.com/",
      icon: "fa-facebook-f",
    },
    {
      _id: 2,
      name: "Instagram",
      link: "https://www.instagram.com/",
      icon: "fa-instagram",
    },
    {
      _id: 3,
      name: "Twitter",
      link: "https://twitter.com/",
      icon: "fa-twitter",
    },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header
        className={`sticky z-30 top-0 w-full flex items-center h-24 ${
          isScrolled ? "bg-victoria shadow" : "bg-victoria shadow"
        }`}
      >
        <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center justify-between">
          <div className="max-w-[200px] h-16">
            <Link to={"/"} className="block w-full h-full">
              <Image
                src={
                  "//images.squarespace-cdn.com/content/v1/5e29ca2a630fb46e59749dcb/1579818899281-LK0MSVQHAPQ5KJ9X2C3N/Upkeep_White_nobackground_Wordmark-02.png?format=1500w"
                }
                alt={"Logo"}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-contain object-left"}
              />
            </Link>
          </div>
          <div className="block xl:hidden">
            <button
              onClick={toggleMenu}
              className="text-carnation-100 focus:outline-none"
            >
              <i className={`fa ${isOpen ? "fa-times" : "fa-bars"} fa-2x`}></i>
            </button>
          </div>
          <div className="hidden xl:flex items-center space-y-4 xl:space-y-0 xl:space-x-10 text-carnation-100">
            <ul className="flex space-x-5">
              {menuData.map((link) => (
                <li key={link._id}>
                  <Link to={link.link} className="cursor-pointer text-lg">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
            {/* <div className="flex space-x-4">
              <a href="tel:7044184619" className="text-lg">
                <i className="fa fa-phone mr-2"></i>704-418-4619
              </a>
              <a href="mailto:info@example.com" className="text-lg">
                <i className="fa fa-envelope mr-2"></i>info@example.com
              </a>
              <div className="flex space-x-2">
                {socialMenu.map((social) => (
                  <a
                    key={social._id}
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg"
                  >
                    <i className={`${social.icon}`}></i>
                  </a>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </header>

      <div
        className={`fixed top-0 left-0 h-full w-full bg-black bg-opacity-50 z-40 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 xl:hidden`}
        onClick={toggleMenu}
      >
        <div
          className="fixed top-0 left-0 h-full w-3/4 max-w-xs bg-victoria z-50 p-6"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={toggleMenu}
            className="text-carnation-100 focus:outline-none mb-4"
          >
            <i className="fa fa-times fa-2x"></i>
          </button>
          <ul className="space-y-4 text-carnation-100">
            {menuData.map((link) => (
              <li key={link._id}>
                <Link
                  to={link.link}
                  className="cursor-pointer text-lg"
                  onClick={toggleMenu}
                >
                  {link.name}
                </Link>
              </li>
            ))}
            {/* <li className="text-lg text-carnation-100 mt-4">
              <a href="tel:7044184619" className="block">
                <i className="fa fa-phone mr-2"></i>704-418-4619
              </a>
              <a href="mailto:info@example.com" className="block mt-2">
                <i className="fa fa-envelope mr-2"></i>info@example.com
              </a>
              <div className="flex space-x-2 mt-4">
                {socialMenu.map((social) => (
                  <a
                    key={social._id}
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg"
                  >
                    <i className={`${social.icon}`}></i>
                  </a>
                ))}
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default DefaultHeader;
