import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";

const DefaultLayout = () => {
  const menuData = {
    logo: "settings && settings?.logo?.url ? settings?.logo?.url : Logo",
    menu: [
      {
        _id: 1,
        name: "About Us",
        link: "/about",
        isParent: false,
      },
      {
        _id: 2,
        name: "Service",
        link: "/service",
        isParent: false,
        subdata: [
          {
            _id:"2-1",
            name:"Medical Treatments",
            link:"/new-fundraising",
          },
          {
            _id:"2-2",
            name:"NGO / Charity",
            link:"/new-fundraising",
          },
          {
            _id:"2-3",
            name:"Other Cause",
            link:"/new-fundraising",
          },
        ]
      },
      {
        _id: 3,
        name: "Products",
        link: "/products",
        isParent: false,
      },
      {
        _id:4,
        name:"Contact",
        link:"/contact",
        isParent: false,
      },
    ],
    options: {
      signinLabel: "Book Now",
      signinLink: "/booknow",
      actionLabel: "Book Now",
      actionLink: `/register`,
    },
  };

  const footerData = {
    logo: "settings && settings?.logo?.url ? settings?.logo?.url : Logo",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: `https://wa.me/${7044184619}`,
        icon: "fa-facebook-f",
      },
      {
        _id: 2,
        name: "Instagram",
        link: "https://www.instagram.com/",
        icon: "fa-instagram",
      },
      {
        _id: 3,
        name: "Twitter",
        link: "https://twitter.com/",
        icon: "fa-twitter",
      },
    ],
    contact: {
      title: "Contact Us",
      subdata: [
        {
          _id: 1,
          label: "Email",
          data: "info@ashmoredecorators.co.uk",
          icon: "fa-envelope",
          type: "mailto",
        },
        {
          _id: 2,
          label: "Contact No.",
          data: "02071694208 / 07868729221",
          icon: "fa-phone-volume",
          type: "tel",
        },
        {
          _id: 3,
          label: "Address",
          data: (
            <>
              <p>{"74 Ashmore Rd, London,"}</p>
              <p>{"W9 3DG"}</p>
            </>
          ),
          icon: "fa-location-dot",
          type: "address",
        },
      ],
    },
    copyright: "Designed By @Weconnectmedia",
  };
  return (
    <>
      <DefaultHeader />
      <Outlet />
      <DefaultFooter data={footerData} />
    </>
  );
};

export default DefaultLayout;
