import React from "react";
import Image from "../elements/Image";
import BannerSkeletonLoader from "../elements/loader/BannerSkeletonLoader";
import DesignButton from "../extra/DesignButton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const HomeBanner = ({ loaded }) => {
  const slides = [
    {
      image: "https://images.unsplash.com/photo-1595871151608-bc7abd1caca3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Slide 1",
      title: "Your neighborhood salon & spa",
      buttonLabel: "Book Now",
      link: "/",
      loaded: loaded,
    },
    {
      image: "https://images.unsplash.com/photo-1580618672591-eb180b1a973f?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Slide 2",
      title: "Your neighborhood salon & spa",
      buttonLabel: "Book Now",
      link: "/",
      loaded: loaded,
    },
    {
      image: "https://images.unsplash.com/photo-1559599101-f09722fb4948?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Slide 3",
      title: "Your neighborhood salon & spa",
      buttonLabel: "Book Now",
      link: "/",
      loaded: loaded,
    },
    {
      image: "https://images.unsplash.com/photo-1562259920-47afc3030ba2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Slide 4",
      title: "Your neighborhood salon & spa",
      buttonLabel: "Book Now",
      link: "/",
      loaded: loaded,
    },
  ];

  if (!slides || !slides[0]?.loaded) {
    return <BannerSkeletonLoader />;
  }

  return (
    <Swiper
      slidesPerView={1}
      navigation={true}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      modules={[Navigation, Autoplay]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="relative w-full h-72 sm:h-96 lg:h-[500px] xl:h-[600px] flex items-center overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-full">
              <Image
                src={slide.image}
                fill={true}
                alt={slide.alt}
                className="h-full w-full object-cover saturate-200 object-center"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-black/85 via-carnation-900/80 to-transparent"></div>
            </div>
            <div className="relative z-10 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
              <div className="w-4/5 sm:w-2/3 md:w-2/3 lg:w-3/5 xl:w-3/5 2xl:w-1/2 3xl:w-2/5 space-y-8">
                <div className="space-y-4">
                  <h1
                    className="font-Lexend text-3xl sm:text-4xl xl:text-5xl uppercase font-bold text-carnation-100"
                    dangerouslySetInnerHTML={{ __html: slide.title }}
                  ></h1>
                </div>
                <div className="flex items-center mt-10">
                  <DesignButton
                    buttonClasses="px-5 uppercase"
                    buttonHasLink={true}
                    buttonLink={slide.link}
                    buttonLabel={slide.buttonLabel}
                    buttonEffect="filled"
                  />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HomeBanner;
