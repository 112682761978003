import React from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import ProductCard from "../components/productCard/ProductCard";
import Hair_Care from "../assets/Haircare.jpg";
import Skin_Care from "../assets/Skincare.jfif";
import Make_Up from "../assets/Makeup.jpg";
import Product_Banner from "../assets/Product_Banner.jpg";
import Hair from "../assets/hair-care.png";
import Skin from "../assets/skin-care.png";
import MakeUp from "../assets/make_up.png";

const Product = () => {
  const productData = {
    loaded: true,
    subdata: [
      {
        _id: 1,
        title: "Hair Care",
        image: Hair_Care,
        description:
          "Davines - founded in 1983 by the Bollati family in Parma. Their products are made from eco-friendly ingredients. Each family in reformulated Davines essential hair care line contains a specific characteristic and function: Nourishment, hydration, volume, shine, protecting, elasticity, smoothness, and daily care. All products are enriched with active ingredients sourced from slow food presidia farms in Italy to contribute to the biodiversity of Davines planet.",
        btnText: "Shop Now",
      },
      {
        _id: 2,
        title: "MakeUp",
        image: Make_Up,
        description:
          "Glo skin beauty provides the full spectrum of skincare and makeup from your lipstick or moisturizer to your foundation. Glo’s award-winning mineral cosmetics are formulated to enhance your natural beauty and nourish your skin at the same time. Glo - known as Glo Minerals, is an antioxidant blend of talc-free, cruelty-free, ultra-fine minerals.",
        btnText: "Shop Now",
      },
      {
        _id: 3,
        title: "Skincare",
        image: Skin_Care,
        description:
          "Image skincare is a clinical skincare brand powered by proven ingredients and smart botanicals. Image skincare offers clean clinical skincare with naturally effective formulas that deliver real results. They provide the latest anti-aging, balancing, hydrating, and illuminating technologies to help the world age later.",
        btnText: "Shop Now",
      },
    ],
  };

  const productTopData = {
    loaded: true,
    subdata: [
      {
        _id: 1,
        title: "Hair Care",
        image: Hair,
      },
      {
        _id: 2,
        title: "Make Up",
        image: MakeUp,
      },
      {
        _id: 3,
        title: "Skin Care",
        image: Skin,
      },
    ],
  };

  return (
    <div className="relative">
      <div
        className={classNames(
          "relative z-10 w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden mb-10",
          "after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-carnation-900/80 after:from-10% after:via-carnation-900/80 after:via-50%"
        )}
      >
        <Image
          src={Product_Banner}
          alt="Product Banner"
          width={"100%"}
          height={"100%"}
          effect={"blur"}
          className="object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <h4 className="text-white text-lg lg:text-xl xl:text-3xl capitalize font-bold text-center">
            Our Products
          </h4>
        </div>
      </div>

      <div className="p-4 flex flex-wrap items-center justify-center mb-8">
        {productTopData?.subdata?.map((item) => (
          <div
            key={item._id}
            className="flex-shrink-0 m-4 relative overflow-hidden bg-victoria rounded-lg shadow-lg group w-full sm:w-64 md:w-72 lg:w-80 xl:w-96 h-80"
          >
            <svg
              className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-125 transition-transform"
              viewBox="0 0 375 283"
              fill="none"
              style={{ opacity: 0.1 }}
            >
              <rect
                x="159.52"
                y="175"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 159.52 175)"
                fill="white"
              />
              <rect
                y="107.48"
                width="152"
                height="152"
                rx="8"
                transform="rotate(-45 0 107.48)"
                fill="white"
              />
            </svg>
            <div className="relative pt-10 px-10 flex items-center justify-center group-hover:scale-105 transition-transform w-full h-64">
              <Image
                src={item?.image}
                alt={item?.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="relative text-white px-6 pb-6 mt-4 flex items-center justify-center">
              <span className="block opacity-75">{item?.title}</span>
            </div>
          </div>
        ))}
      </div>

      <hr className="bg-victoria h-1 mx-4 md:mx-8 lg:mx-16 mb-16" />

      {productData?.subdata?.map((item) => (
        <ProductCard key={item._id} data={item} />
      ))}

      <div className="relative px-6 py-5 my-5 productEndBanner">
        <div className="bg-carnation-900/35 px-5 py-20 rounded-lg">
          <div className="relative text-white font-bold text-5xl text-center pb-3 mb-8 uppercase ">
            have a fabulous spa day
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
