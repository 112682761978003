import React, { useCallback, useEffect, useRef, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import HomeBanner from "../components/banner/HomeBanner";
import ServiceCard from "../components/serviceCard/ServiceCard";
import BlogCard from "../components/blogs/BlogCard";
import SingleBlog from "../components/blogs/SingleBlog";
import AboutUsCard from "../components/about/AboutUsCard";
import Button from "../components/form/Button";
import Image from "../components/elements/Image";
import DesignButton from "../components/extra/DesignButton";
import Blogbg from ".././../src/assets/2148493042.jpg";

const Home = ({ data }) => {
  setTitle("UpKeep Salon & Spa - Home");
  const [loaded, setLoaded] = useState(true);

  const aboutUsData = {
    image:
      "https://images.unsplash.com/photo-1618221381711-42ca8ab6e908?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title:
      // "Many desktop <span class='text-carnation-500'>publishing</span> packages",
      "About Us",
    // subtitle: "Donate to contribution",
    description:
      "Welcome to Upkeep Salon and Spa, located in Cameron Heights Edmonton. We are community-focused and are guided by heart and soul. We offer top of the line skin care and hair products such as Glo skin care and Glo Minerals makeup line, Image skin care, and Davines hair care products.Our Mission is to provide the highest level of customer service to each client, thereby establishing long lasting relationships of trust and commitment. We want to make your salon experience as unique and memorable as you are. Our goal is to bring you the freshest styles and ideas by keeping up with current trends and educating ourselves on best practices.",
    actionLink: "/",
    actionLabel: "Book Now",
    loaded: loaded,
  };


  return (
    <>
      <HomeBanner loaded={loaded} />
      <AboutUsCard data={aboutUsData} />
      <div className="relative my-10 overflow-hidden">
      <div className="relative overflow-hidden w-full z-0 h-[400px] sm:h-[400px] md:h-[500px] lg:h-[600px] xl:h-[680px] flex items-center">
        <div className="absolute top-0 left-0 w-full h-full z-[-1] after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-carnation-900/90 after:from-10% after:via-black/10 after:via-50%">
          <Image
            src={Blogbg}
            alt={"Background Image"}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover object-center"}
          />
        </div>
        <div className="relative z-[1] w-full px-4 sm:px-6 md:px-8 lg:px-12 xl:px-16 2xl:px-20 mx-auto flex flex-col lg:flex-row items-center justify-center lg:justify-start">
          <div className="w-full lg:w-1/2 px-4 lg:px-8 py-6 space-y-8">
            <div className="space-y-6">
              <div className="text-lg sm:text-xl lg:text-2xl text-slate-300">
                <h2 className="font-Lexend text-3xl sm:text-4xl lg:text-5xl uppercase font-bold text-carnation-100">
                  Location
                </h2>
                <p className="mt-2 text-base sm:text-lg lg:text-xl font-normal">
                  621 Cameron Heights Drive NW
                  <br />
                  Edmonton, Alberta T6M 0L9
                  <br />
                  T. 780.443.6162
                </p>
              </div>
              <div className="text-lg sm:text-xl lg:text-2xl text-slate-300">
                <h2 className="font-Lexend text-3xl sm:text-4xl lg:text-5xl uppercase font-bold text-carnation-100">
                  Hours
                </h2>
                <p className="mt-2 text-base sm:text-lg lg:text-xl font-normal">
                  Monday: 10:00AM - 6:00PM
                  <br />
                  Tuesday to Friday: 10:00AM - 7:00PM
                  <br />
                  Saturday: 9:00 AM - 5:00PM
                  <br />
                  Sunday: Closed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <ServiceCard></ServiceCard>
      <div className="relative px-6 py-5 my-5 expert">
        <div className="bg-carnation-900/85 px-5 py-16 rounded-lg">
          <div className="relative text-white font-bold text-2xl text-center pb-3 mb-8 uppercase after:absolute after:border-b-2 after:border-white after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
            Book with us
          </div>
          <div className="text-white text-xl capitalize w-[90%] mx-auto mb-5 mt-10 font-bold text-center">
            We provide full consultation and direction with all of our Services.
            We offer spa themed parties for any occasion.
          </div>
          <div className="text-white text-3xl capitalize w-[90%] mx-auto mb-8 text-center">
            Glowing Skin is Always In
          </div>
          <div className="flex justify-center">
            <Button
              buttonLabel={"Book Your Appointment Today"}
              buttonLabelClasses={"!text-sm"}
              buttonClasses={
                "!px-4 !py-3 sm:!px-6 sm:!py-4 lg:!px-8 lg:!py-5 xl:!px-10 xl:!py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-black"
              }
              buttonIcon={"fa-solid fa-phone-volume"}
              buttonIconPosition={"left"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
