import React, { useState } from "react";

const ServiceItem = ({ data, onSelect, isSelected }) => {
  // console.log(data, "lisrrrr adatadtdt");

  const handleClick = () => {
    onSelect(data?.code);
  };

  return (
    <div
      className={`p-2 cursor-pointer  rounded-md text-center ${
        isSelected
          ? "border-b-4 border-victoria font-bold text-org-darkBlue "
          : " hover:text-org-darkGray text-victoria"
      } whitespace-nowrap`}
      onClick={handleClick}
    >
      <div>{data?.name}</div>
    </div>
  );
};

export default ServiceItem;