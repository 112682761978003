import React from "react";
import Image from "../elements/Image";
import DesignButton from "../extra/DesignButton";

const AboutUsSection = () => {
  return (
    <div className="relative my-10 overflow-hidden">
      <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-10 lg:gap-0">
        <div className="relative px-6 sm:px-10 py-12 z-[2] bg-slate-200 rounded-xl order-2 lg:order-1 lg:w-4/4 mx-auto lg:ml-10">
          <div className="relative text-victoria font-bold text-2xl text-center pb-3 mb-8 uppercase after:absolute after:border-b-2 after:border-carnation-600 after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto animate-pulse">
            About Us
          </div>
          <div className="text-black font-normal mb-5 text-justify">
            Welcome to Upkeep Salon and Spa, located in Cameron Heights Edmonton. We are community-focused and are guided by heart and soul. We offer top of the line skin care and hair products such as Glo skin care and Glo Minerals makeup line, Image skin care, and Davines hair care products. Our Mission is to provide the highest level of customer service to each client, thereby establishing long lasting relationships of trust and commitment. We want to make your salon experience as unique and memorable as you are. Our goal is to bring you the freshest styles and ideas by keeping up with current trends and educating ourselves on best practices.
          </div>
          <div className="flex justify-center">
            <DesignButton
              buttonLabel={"Find Out More"}
              buttonLabelClasses={"!text-sm"}
              buttonClasses={
                "!px-5 !py-2 hover:!bg-black transition-all duration-300 !text-sm !bg-victoria"
              }
              buttonIcon={"fa-sharp fa-solid fa-arrow-right"}
              buttonIconPosition={"left"}
              buttonEffect="filled"
            />
          </div>
        </div>
        <div className="relative w-full h-64 lg:h-auto order-1 lg:order-2 transform transition-transform hover:scale-110">
          <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-50 transition-opacity duration-300"></div>
          <Image
            src={
              "https://res.heraldm.com/content/image/2021/11/10/20211110000108_0.jpg"
            }
            alt={"About Us Image"}
            className={"w-full h-full object-cover"}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
