import React from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";

const Contact = () => {
  return (
    <div className="relative ">
      <div
        className={classNames(
          "relative z-[2] w-full h-[400px] lg:h-[500px] xl:h-[650px] overflow-hidden mb-10"
        )}
      >
        <Image
          src="https://images.squarespace-cdn.com/content/v1/5e29ca2a630fb46e59749dcb/1594920184754-TKI1B9YDVBEJ8E5KDFJL/JennySalonPreview-004.jpg"
          alt="Background image"
          layout="fill"
          objectFit="cover"
          className="object-cover"
        />
        <div className="absolute left-1/2 transform -translate-x-1/2 bottom-60 lg:bottom-20 xl:bottom-40 text-center text-carnation-900 text-xl lg:text-2xl xl:text-4xl capitalize z-10 font-bold">
          Contact Us
        </div>
      </div>
      <div className="relative">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col justify-center px-8 py-20">
            <h1 className="font-bold text-3xl lg:text-4xl xl:text-5xl text-center pb-3 mb-8   animate-bounce text-carnation-900 relative after:absolute after:border-b-2 after:border-carnation-900 after:bottom-0 after:left-1/2 after:transform after:-translate-x-1/2 after:w-28 after:h-1">
              Contact us
            </h1>
            <div className="text-lg lg:text-xl xl:text-2xl text-slate-500 font-bold">
              621 CAMERON HEIGHTS DRIVE NW, EDMONTON, AB, T6M 0L9
              <br />
              780.443.6162
              <br />
              INFO@UPKEEPSALON.COM
            </div>
          </div>
          <div className="flex justify-center px-8 py-20 overflow-hidden">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.8983800938427!2d-113.63143559999999!3d53.47027620000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x539ff544613d335f%3A0x9f1dfc29a93427af!2sUpkeep%20Salon%20And%20Spa!5e0!3m2!1sen!2sin!4v1721454970010!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="w-full h-[400px] lg:h-[500px] xl:h-[600px]"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="bg-victoria py-10 mx-auto text-center">
        <div className="relative text-slate-100 font-bold text-5xl text-center pb-3 mb-8 uppercase after:absolute after:border-b-2 after:border-white after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
          Set aside some time for yourself.
        </div>
        <div className="text-white text-xl capitalize w-[90%] mx-auto mb-5 mt-10 font-bold text-center">
          For cancellations, we require 24 hours notice.
        </div>
        <div className="flex justify-center">
          <Button
            buttonLabel={"Book An Appointment"}
            buttonLabelClasses={"!text-sm"}
            buttonClasses={
              "!px-4 !py-3 sm:!px-6 sm:!py-4 lg:!px-8 lg:!py-5 xl:!px-10 xl:!py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-black"
            }
          />
        </div>
      </div>

      <div className="relative px-6 py-5 my-5 contactEndBanner">
        <div className="bg-carnation-900/35 px-5 py-20 rounded-lg">
          <div className="relative text-white font-bold text-5xl text-center pb-3 mb-8 uppercase ">
            be your own kind of beautiful
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
