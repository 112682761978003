import React from "react";
import Image from "../components/elements/Image";
import { classNames } from "../helpers/classNames";

const About = () => {
  const aboutImage = [
    {
      _id: 1,
      image:
        "https://img.freepik.com/free-photo/female-hairdresser-using-hairbrush-hair-dryer_329181-1929.jpg?t=st=1721322622~exp=1721326222~hmac=579706fe4ab9f34b1b9669af2f4d3e37db90151be596d0481019912004832fe2&w=996",
      title: "Write a Review",
    },
    {
      _id: 2,
      image:
        "https://images.unsplash.com/photo-1522337360788-8b13dee7a37e?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Write a Review",
    },
    {
      _id: 3,
      image:
        "https://img.freepik.com/free-photo/beautician-with-brush-applies-white-moisturizing-mask-face-young-girl-client-spa-beauty-salon_343596-4247.jpg?t=st=1721322684~exp=1721326284~hmac=caf4b9541cc02215a2c2ffdcdcf0553829675b209e2a22bc6fdaab035cee29b9&w=996",
      title: "Write a Review",
    },
  ];
  const aboutImage2 = [
    {
      _id: 1,
      image:
        "https://img.freepik.com/free-photo/woman-hairdresser-salon_144627-8818.jpg?t=st=1721324041~exp=1721327641~hmac=49c7d713ed5ffd22d630b87cda5a0115937a907901415dffac1a2aa706a891f4&w=996",
      title: "Write a Review",
    },
    {
      _id: 2,
      image:
        "https://img.freepik.com/free-photo/woman-washing-head-hairsalon_1157-27179.jpg?t=st=1721323746~exp=1721327346~hmac=70055857b8988df2053ac8bba5f300974a70c712c5537975bc4b0aeff2f68124&w=996",
      title: "Write a Review",
    },
    {
      _id: 3,
      image:
        "https://img.freepik.com/free-photo/two-girls-are-doing-make-up-front-big-professional-mirror_231208-3557.jpg?t=st=1721326444~exp=1721330044~hmac=12a8c08c7d980ab8b08ef516a978f6ccf9dda20945c071026005d82cfd4adefb&w=996",
      title: "Write a Review",
    },
  ];

  return (
    <div className="relative ">
      <div
        className={classNames(
          "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden mb-10",
          "after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-carnation-900/80 after:from-10% after:via-carnation-900/80 after:via-50%"
        )}
      >
        <Image
          src={
            "https://images.unsplash.com/photo-1521590832167-7bcbfaa6381f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt={""}
          width={"100%"}
          height={"100%"}
          effect={"blur"}
          className={" object-cover"}
        />
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <h4 className="text-white text-lg lg:text-xl xl:text-3xl capitalize font-bold text-center">
            About Us
          </h4>
        </div>
      </div>
      <div className="my-10 overflow-hidden">
        <div className="relative">
          <div className="grid grid-cols-1 lg:grid-cols-[60%_40%]">
            <div className="relative justify-center bg-slate-200 px-8 py-20">
              <div className="grid w-full mt-3">
                <Image
                  src={
                    "https://images.unsplash.com/photo-1521590832167-7bcbfaa6381f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  }
                  alt={""}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  className={" object-cover"}
                />
              </div>
            </div>
            <div className="relative bg-slate-900 px-8 py-20 space-y-5 ">
              <div className="relative text-2xl font-bold text-center text-white pb-3 after:absolute after:border-b-2 after:border-carnation-500 after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
                ABOUT US
              </div>
              <div className="text-base font-normal text-slate-200">
                Welcome to Upkeep Salon and Spa, located in Cameron Heights
                Edmonton. We are community-focused and are guided by heart and
                soul.
              </div>
              <div className="text-base font-normal text-slate-200">
                Our Mission is to provide the highest level of customer service
                to each client, thereby establishing long lasting relationships
                of trust and commitment. We want to make your salon experience
                as unique and memorable as you are. Our goal is to bring you the
                freshest styles and ideas by keeping up with current trends and
                educating ourselves on best practices.
              </div>
              <ul className="grid grid-cols-2 xl:flex justify-center items-center border-t border-slate-500 pt-10 !mt-10 gap-6">
                {aboutImage?.map((item, index) => (
                  <div className="block relative overflow-hidden z-[1] gallery group">
                    <div className="w-56 mx-auto" key={index}>
                      <Image
                        src={item.image}
                        alt={""}
                        className="object-cover"
                      />
                    </div>
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center opacity-0 transform translate-y-[-50px] transition-all ease-in duration-[.8s] z-[5] group-hover:opacity-100 group-hover:transform group-hover:translate-y-[0] group-hover:delay-[.5s]">
                      <span className="relative top-0 left-0 bottom-0 right-0 font-semibold text-md text-carnation-500 mx-auto text-center pt-2 rounded-sm">
                        Book Now
                      </span>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="grid grid-cols-1 lg:grid-cols-[60%_40%]">
            <div className="relative bg-slate-900 px-8 py-20 space-y-5 ">
              <div className="relative text-2xl font-bold text-center text-white pb-3 after:absolute after:border-b-2 after:border-carnation-500 after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
                JENNY SUNNER
              </div>
              <div className="text-base font-normal text-slate-200">
                Proud owner and director, senior aesthetician and laser tech.
                She has been a part of this industry since 2006. Her passion
                about this industry always encourages her to learn new skills.
                She keeps up with new advanced skin care techniques when they
                come in the market. Her goal is always to give her clients
                quality service. She has a particular passion for brows which
                has led to her become a specialist in her field.
              </div>
              <ul className="grid grid-cols-2 xl:flex justify-center items-center border-t border-slate-500 pt-10 !mt-10 gap-6">
                {aboutImage2?.map((item, index) => (
                  <div className="block relative overflow-hidden z-[1] gallery group">
                    <div className="w-56 mx-auto" key={index}>
                      <Image
                        src={item.image}
                        alt={""}
                        className="object-cover"
                      />
                    </div>
                    <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center opacity-0 transform translate-y-[-50px] transition-all ease-in duration-[.8s] z-[5] group-hover:opacity-100 group-hover:transform group-hover:translate-y-[0] group-hover:delay-[.5s]">
                      <span className="relative top-0 left-0 bottom-0 right-0 font-semibold text-md text-carnation-500 mx-auto text-center pt-2 rounded-sm">
                        Book Now
                      </span>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
            <div className="relative justify-center bg-slate-200 px-8 py-20 overflow-hidden">
              <div className="grid w-full mt-3 justify-center relative h-64 lg:h-auto order-1 lg:order-2 transform transition-transform hover:scale-125">
                <Image
                  src="https://images.squarespace-cdn.com/content/v1/5e29ca2a630fb46e59749dcb/1594919563173-K6E83FASXFVC3DQJT239/JennySalonPreview-007.jpg?format=1500w"
                  alt=""
                  effect="blur"
                  width={400}
                  className="object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative px-6 py-5 my-5 aboutEndBanner">
        <div className="bg-carnation-900/35 px-5 py-20 rounded-lg">
          <div className="relative text-white font-bold text-5xl text-center pb-3 mb-8 uppercase ">
            Relax, refresh, recharge
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
