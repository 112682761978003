import React, { useEffect, useState } from "react";
import ServiceList from "../components/serviceList/ServiceList";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import { times } from "lodash";

const ServiceDetails = () => {
  const hairService = [
    { title: "Menʼs cut", price: "$ 25" },
    { title: "Ladies cut & style", price: "$ 60&up" },
    { title: "Roots touch up", price: "$ 80&up" },
    { title: "Solid colour", price: "$ 100&up" },
    { title: "Highlights", price: "$ 160+" },
    { title: "Updoʼs", price: "$ 60+" },
    { title: "Wash and blow dryer", price: "$ 45+" },
    { title: "Balayage", price: "$ 200" },
    { title: "Kids / boys cut", price: "$ 20" },
    { title: "Kids / girls cut", price: "$ 25+" },
    { title: "Hair spa", price: "$ 99+" },
    { title: "Ladies hair trim", price: "$ 30&up" },
  ];
  const handFootTreatment = [
    {
      title: "MANICURES",
      details: [
        {
          name: "Classic manicure",
          price: "$30",
          description:
            "Pamper yourself as we help you achieve your desired look by shaping and filing your nails, cleaning up your cuticles, all with a relaxing hand and arm massage. Finished up with choice of regular polish - 35 min",
        },
        {
          name: "Gel manicure",
          price: "$50",
          description:
            "Treat your hands with filing, nail shaping, softening and cuticle clean-up, follow by a relaxing hand and arms massage. Finished up with your choice of gel polish colour that lasts about up to 3 weeks - 45 min",
        },
        {
          name: "Luxury manicure w/ paraffin treatment",
          price: "$50",
          description:
            "This luxury treatment includes nail-filing, shaping, softening and cuticle clean-up. Rehydrate your skin with a hand and arm massage and a paraffin dip for 10-15 min. Paraffin wax leaves your skin soft, smooth, hydrated and helps to relieve joint pain. Finished up with your choice of polish - 45 Min",
        },
        {
          name: "Gel Polish Application (Hands)",
          price: "$30",
          description:
            "Gel polish application on the hands, includes nail filing, buff and cuticle cutting - 30 Min",
        },
      ],
    },
    {
      title: "PEDICURES",
      details: [
        {
          name: "Classic pedicure",
          price: "$50",
          description:
            "Premium nail and foot care with a luxurious scrub, followed by a de-stressing massage from your knees to toes (suggestion: try our Polish Add On) - 60 min",
        },
        {
          name: "Pedi on the go",
          price: "$35",
          description:
            "Quick pedicure, ideal for a busy lifestyle. Nail and foot care + polish - 30 min",
        },
        {
          name: "Luxury pedicure w/ paraffin wax",
          price: "$70",
          description:
            "Luxury pedicure allows you to drift away and relax your feet will enjoying a therapeutic soak, followed by an exfoliating sugar scrub. This treatment includes filing, shaping, cuticle work, callus softening and removal. Afterwards we apply a detoxifying mask you will enjoy a de-stressing massage from your knees to your toes. This is followed by a foot soak with hot paraffin that treats dry, cracked skin, arthritis and stiff joints. Finished with your choice of polish (suggestion: try our Gel Polish Add On) - 75min",
        },
        {
          name: "Gel pedicure",
          price: "$70 ON SALE NOW-$65",
          description:
            "Pamper yourself with this treatment that includes buff and nail filing, cuticle work, callus removal follow by an exfoliating sugar scrub and de-stressing massage from your knees to your toes. Finished up with your choice of gel polish colour that will last about 3-4 weeks - 60 min",
        },
        {
          name: "Princess pedicure (Ages 4 to 12)",
          price: "$30",
          description:
            "This pampering foot treatment will show your little princess how to keep her feet looking and feeling their best. Feet are soaked, moisturized and massaged, and toenails are buffed and shaped. Finished by their choice of colour - 35 min",
        },
        {
          name: "Foot Gel polish Application",
          price: "$30",
          description:
            "Includes nail filing, buff and cuticle cutting - 25 min",
        },
      ],
    },
  ];
  const handFootTreatmentAddOnsData = {
    title: "ADD ONS",
    details: [
      { title: "Gel Polish", price: "$ 20" },
      { title: "Gel Polish Removal", price: "$ 10" },
      { title: "Paraffin", price: "$ 15" },
      { title: "French Tips", price: "$ 10" },
      { title: "Extra massage 10 min", price: "$ 10" },
      { title: "French Tips With Gel Polish", price: "$ 15" },
    ],
  };
  const relaxationMassage = [
    { title: "30 Minute Massage", price: "$ 45" },
    { title: "60 Minute Massage", price: "$ 85" },
  ];

  const spaPackageData = [
    {
      name: "RELAX & REFRESH",
      price: "$ 165",
      description:
        "Enjoy our classic manicure/pedicure and 60 min relaxation massage, Complimentary eye brow threading - 2 hrs 35 min",
    },
    {
      name: "A NEW YOU",
      price: "$ 255",
      description:
        "Combining our most popular treatments in this package. The Perfect way to escape and rejuvenate: 60 minute relaxation massage, 60 min facial, Classic pedicure, Complementary eye brow threading - 3 hrs",
    },
    {
      name: "LUXURY TOUCH",
      price: "$ 315",
      description:
        "Pamper yourself with our luxury touch package that includes everything you need: Signature hydra facial, Luxury pedicure, Luxury manicure, Complimentary eye brow threading - 3 hrs 15 min",
    },
    {
      name: "SWEET ESCAPE (FOR TWO)",
      price: "$ 330",
      description:
        "Celebrate your special occasion with a: 1 hour relaxation massage, Classic pedicure, Classic manicure, Complimentary eyebrow threading - 2 hour 45 min",
    },
  ];
  const laserHairRemovalData = [
    {
      title: "",
      details: [
        {
          title: "UPPER LIP LASER",
          regularPrice: "$ 55",
          salePrice: "$ 40 With pkg of 3",
        },
        {
          title: "CHIN LASER",
          regularPrice: "$ 55",
          salePrice: "$ 40 With pkg of 3",
        },
        {
          title: "UPPER LIP+CHIN LASER",
          regularPrice: "$75 / Sale price$60 With pkg of 3",
          salePrice: " ",
        },
        {
          title: "FULL FACE LASER",
          regularPrice: "$ 125",
          salePrice: "$ 100 WITH pkg of 3",
        },
        {
          title: "NECK",
          regularPrice: "$75 / SALE PRICE$50 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "EARS",
          regularPrice: "$65 / SALE PRICES$45 pkg of 3",
          salePrice: " ",
        },
        {
          title: "SIDES OF FACE",
          regularPrice: "$65 / SALE PRICE$45 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "FULL FACE WITH NECK",
          regularPrice: "$150 / sale price$125 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "UNDER ARMS",
          regularPrice: "$75 / SALE PRICE$65 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "FULL ARMS",
          regularPrice: "$225 / sale PRICE $195 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "UPPER ARMS",
          regularPrice: "$150 / SALE PRICE $125 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Hands & Fingers",
          regularPrice: "$150 / SALE PRICE $125 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Full Back",
          regularPrice: "$265 / SALE PRICE $240 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Full Front",
          regularPrice: "$265 / SALE PRICE $240 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Stomach",
          regularPrice: "$ 175",
          salePrice: "$ 130-$ 150 WITH pkg of 3",
        },
        {
          title: "Lower Back",
          regularPrice: "$175 SALE PRICE $130-$150 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Full Legs",
          regularPrice: "$375 / SALE PRICE $325 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Full Legs",
          regularPrice: "$225 / SALE PRICE $190 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Lowe- Half Legs",
          regularPrice: "$200 / SALE PRICE $185 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Brazilian",
          regularPrice: "$175+ / SALE PRICE $155+WITH pkg of 3",
          salePrice: "$ 145",
        },
        {
          title: "Bikini Line",
          regularPrice: "$110 / SALE PRICE $85 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "French Bikini Line",
          regularPrice: "$130+ / SALE PRICE$100+ WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Buttocks",
          regularPrice: "$120+ / SALE PRICE$100+ WITH pkg of 3",
          salePrice: " ",
        },
      ],
    },
    {
      title: "Gentleman Laser Hair Removal - REGULAR PRICE",
      details: [
        {
          title: "Full Back with Shoulder",
          regularPrice: "$270 / SALE PRICE $250 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Full Front (chest & Stomach)",
          regularPrice: "$270 / SALE PRICE $250 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Cheeks",
          regularPrice: "$90+ / SALE PRICE$70+WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Forehead",
          regularPrice: "$70 / SALE PRICE$50+ WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Full Arms",
          regularPrice: "$245 / SALE PRICE $225 WITH pkg of 3",
          salePrice: "$200",
        },
        {
          title: "Half Arms",
          regularPrice: "$175 / SALE PRICE $150 pkg of 3",
          salePrice: " ",
        },
        {
          title: "Full Legs",
          regularPrice: "$380 / SALE PRICE $340 WITH pkg of 3",
          salePrice: " ",
        },
        {
          title: "Half Legs",
          regularPrice: "$225 / SALE PRICE $200 WITH pkg of 3",
          salePrice: " ",
        },
      ],
    },
  ];

  const microbladingData = [
    {
      title: "",
      details: [
        {
          name: "Microblading",
          price: "$275 / $100 4 wk touch up",
          description:
            "A semi-permanent technique enhancing the appearance of eye brows. Using a super fine pen made of several needles to deposit semi -permanent pigment into skin with short fine strokes resembling like real hairs. It will last about 12-18 months, longevity of the pigment will depend on your skin type.",
        },
        {
          name: "Ombre powder fill brows",
          price: "$350 / $100 4 wk touch up",
          description:
            "The technique is described by its name, with results being powdery with a faded front and a crisp tail, resembling the look of makeup. While ombre powder brows have a more “filled-in” look, the healed results appear like softly shaded brow pencil or powder. It will last about up to 2 years depend on skin.",
        },
        {
          name: "Hybrid Brows",
          price: "$350 / $100 4 wk touch up",
          description:
            "Hybrid is a combination of microbladed hair strokes and a powder fill. It gives an incredibly natural looking brow. Itʼs a bit more defined and filled in than just straight microbladed brows and a bit less solid and dramatic than the ombre powder fill. It will last about 12-18 months depending on skin type.",
        },
      ],
    },
  ];
  const facialsData = [
    { title: "Anti -aging facial", price: "$ 125", time: "60 min" },
    { title: "Vitamin c antioxiant facials", price: "$ 125", time: "60 min" },
    { title: "Upkeep signature facial", price: "$ 165", time: "90 min" },
    { title: "Acne facial", price: "$ 85", time: "45 min" },
    { title: "Back facial", price: "$ 120+", time: "60 min" },
    { title: "Body polish", price: "$ 175", time: "90 min" },
    { title: "Express facial", price: "$ 60", time: "30 min" },
  ];

  const browsAndLashesData = [
    {
      title: "THREADING",
      description:
        "Threading is the most precise way to remove hair with absolutely no damage to skin. Using specialized thread our experts can remove individual hairs to give you perfect shape.",
      details: [
        { title: "Eyebrow threading", price: "$ 12" },
        { title: "Threading upper lips", price: "$ 8" },
        { title: "Thread full face", price: "$ 40" },
        { title: "Thread sides", price: "$ 10" },
        { title: "Thread chin", price: "$ 10" },
        { title: "Thread neck", price: "$ 10" },
        { title: "Eyebrow - upper lip - chin", price: "$ 30" },
        { title: "Forehead", price: "$ 8" },
        { title: "HENNA BROW", price: "$ 45" },
        { title: "BROW LAMINATION", price: "$ 65" },
      ],
    },
    {
      title: "TINTING",
      description:
        "Eyebrow tinting will semi-permanently change the colour of your eyebrow hairs, making them look fuller. When we combine eyebrow threading and tint together it will give them a more defined, natural look. Eyebrow and eye lash tint is an easy way to make them look fuller. Define and darken with your choice of colour, without a slick of make-up. It will last up to 2-3 weeks.",
      details: [
        { title: "Eyebrow tint", price: "$ 15" },
        { title: "Eye lash tint", price: "$ 30" },
      ],
    },
    {
      title: "EYE LASH LIFT",
      description:
        "It’s like a perm for your natural eye lashes (but without chemicals). It uplifts and curls them from the base of the lash, making them stand out and look longer. When we’re finished, your eyes look larger, your lashes look longer and fuller.",
      details: [
        { title: "Eye lash lift or perm", price: "$ 70" },
        { title: "Eye lash lift and tint", price: "$ 100" },
      ],
    },
  ];
  const waxingData = [
    {
      title: "",
      description: "",
      details: [
        { title: "Eyebrow waxing", price: "$ 15" },
        { title: "Upper lip waxing", price: "$ 10" },
        { title: "Full face waxing", price: "$ 42" },
        { title: "Under arm waxing", price: "$ 15" },
        { title: "Side wax", price: "$ 12" },
        { title: "Chin", price: "$ 12" },
        { title: "Full arm", price: "$ 40" },
        { title: "Full legs", price: "$ 50" },
        { title: "Upper legs", price: "$ 35" },
        { title: "Lower legs", price: "$ 30" },
        { title: "Bikini line", price: "$ 30" },
        { title: "French bikini", price: "$ 30" },
        { title: "Brazilian", price: "$ 60" },
        { title: "Full back", price: "$ 50" },
        { title: "Full back with shoulder", price: "$ 65" },
        { title: "Full front/stomach", price: "$ 50" },
        { title: "Ears wax", price: "$ 10" },
        { title: "Nose wax", price: "$ 10" },
      ],
    },
    {
      title: "HARD WAX",
      description:
        "Hard wax is also known as stripless wax. Hard wax is less painful and more gentle on the skin because hard wax only attaches to hair not the skin, reducing discomfort and minimizing the possibility of irritation.",
      details: [
        { title: "Hard wax brows", price: "$ 15" },
        { title: "Upper lips", price: "$ 12" },
        { title: "Hard wax full face", price: "$ 45" },
        { title: "Hard wax under arms", price: "$ 20" },
        { title: "Hard wax bikini", price: "$ 40" },
        { title: "Hard wax french bikini", price: "$ 50" },
        { title: "Hard wax brazilian", price: "$ 70" },
      ],
    },
  ];
  const facialSkinTreatment = [
    {
      title: "MICRO-NEEDLING",
      description:
        "Micro-needling is an ideal choice for non-surgical and non-ablative treatment of various skin conditions, such as wrinkles, crows feet, under eye bags, dark spots, smile lines, sagging skin, stretch marks, and acne scars. We use Inno-pen which is a safer and more effective device. It helps to create many micro channels that increase product absorbency by up to 85% and improves skin elasticity and texture.",
      details: [
        {
          title: "Full Face Treatment",
          regularPrice: "$350 / ON SALE-$299 each session with pkg of 3",
          salePrice: " ",
          description: "",
        },
        {
          title: "Face with Neck",
          regularPrice: "$375 / ON SALE$350 each session with pkg of 3",
          salePrice: " ",
          description: "",
        },
      ],
    },
    {
      title: "HYDRA-FACIAL",
      description:
        "The award-winning hydra-facial md is an incredible non-invasive facial treatment. The deep cleanse, exfoliates and extracts impurities from the skin and restores youthful healthy skin. It is ideal for sun damage /hyper-pigmentation, uneven skin tone, fine lines and wrinkles, dry or dehydrated skin, loss of skin clarity and firmness, oily and acne prone skin. Also, you can customize your hydra-facial treatment with different boosters that’s suit your individual needs.",
      details: [
        {
          title: "Signature hydra facial",
          regularPrice: "$199 SALE PRICE -$185",
          salePrice: " ",
          description:
            "This treatment deeply cleanses, exfoliates, extracts and hydrates the skin utilizing super serums filled with antioxidants ,mild peel, peptides, and hyaluronic acid .this treatment includes with led light therapy to further reduce the visible signs of aging - 45min",
        },
        {
          title: "Delux hydra facial",
          regularPrice: "$240 SALE PRICE -$225",
          salePrice: " ",
          description:
            "An invigorating treatment that includes all of the essentials of the signature hydrafacial while addressing your specific skin concerns with a booster of your choice • Britenol booster: Minimize the appearance of skin tone, texture,and elasticity. • Dermabuilder booster: Great for reducing the appearance of fine lines and wrinkles - 45-60 min",
        },
      ],
    },
    {
      title: "MICRO-DERMABRASION",
      description:
        "Micro dermabrasion is a minimally invasive procedure used to renew overall skin tone and texture. It can improve the appearance of sun damage, wrinkles, fine lines, age spots, acne scarring, melasma, and other skin-related concerns and conditions - 60 min - $150",
      details: [],
    },
    {
      title: "DERMA-PLANING",
      description:
        "Derma-planing is a surface exfoliation that removes the superficial layer of dead skin and the vellus hairs (peach fuzz) using a disposable, medical-grade blade and handle. We see immediate brightness and it minimizes dry skin, and increases the skin’s ability to absorb products better. It can be used in combination of chemical peels, facials and HydraFacial treatments - 45 min - $90 ON SALE NOW-$80",
      details: [],
    },
  ];

  const categoryData = [
    {
      id: 1,
      name: "HAIR SERVICES",
      details: "For all colour services, a consultation is required.",
      code: "hair-service",
      data: (
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {hairService?.map((service, index) => (
              <div
                key={index}
                className="bg-slate-900 rounded-lg shadow-xl p-6 transition-transform transform hover:scale-105"
              >
                <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-carnation-400">
                  {service.title}
                </h2>
                <p className="text-lg sm:text-xl font-bold text-slate-200">
                  {service.price}
                </p>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      id: 2,
      name: "HAND & FOOT TREATMENT",
      details: "",
      code: "hand-foot-treatment",
      data: (
        <div className="container mx-auto p-4">
          {handFootTreatment.map((treatment, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center mb-6">
                {treatment.title}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {treatment.details.map((detail, idx) => (
                  <div
                    key={idx}
                    className="bg-slate-900 rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105"
                  >
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4 pb-2 text-carnation-400 border-b-2 border-carnation-400 text-center">
                      {detail.name}
                    </h3>
                    <p className="text-slate-100 mb-4 text-2xl sm:text-3xl">
                      {detail.price}
                    </p>
                    <p className="text-gray-400">{detail.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <h2 className="text-2xl sm:text-3xl font-semibold mb-6 text-slate-900 text-center">
            {handFootTreatmentAddOnsData?.title}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {handFootTreatmentAddOnsData?.details?.map((service, index) => (
              <div
                key={index}
                className="bg-slate-900 rounded-lg shadow-xl p-6 transition-transform transform hover:scale-105"
              >
                <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-carnation-400">
                  {service?.title}
                </h2>
                <p className="text-slate-200 font-bold text-lg sm:text-xl">
                  {service.price}
                </p>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      id: 3,
      name: "Waxing",
      code: "waxing",
      details:
        "Waxing, known as hair removal, also exfoliates too. Waxing removes hair from roots. Results last up to 4 weeks. After waxing, hair grows back thinner and softer.",
      data: (
        <div className="container mx-auto p-4">
          {waxingData.map((item, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center mb-6">
                {item.title}
              </h2>
              <div className="text-md sm:text-lg xl:text-xl text-slate-600 max-w-xl mx-auto mb-6">
                {item?.description || ""}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {item?.details?.map((service, index) => (
                  <div
                    key={index}
                    className="bg-slate-900 rounded-lg shadow-xl p-6 transition-transform transform hover:scale-105"
                  >
                    <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-carnation-400">
                      {service.title}
                    </h2>
                    <p className="text-slate-200 font-bold text-lg sm:text-xl">
                      {service.price}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      id: 4,
      name: "Brows & lashes",
      code: "brows-lashes",
      details:
        "Threading is the most precise way to remove hair with absolutely no damage to skin. Using specialized thread our experts can remove individual hairs to give you perfect shape.",
      data: (
        <div className="container mx-auto p-4">
          {browsAndLashesData.map((item, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center mb-6">
                {item.title}
              </h2>
              <div className="text-md sm:text-lg xl:text-xl text-slate-600 max-w-xl mx-auto mb-6">
                {item?.description || ""}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {item?.details?.map((service, index) => (
                  <div
                    key={index}
                    className="bg-slate-900 rounded-lg shadow-xl p-6 transition-transform transform hover:scale-105"
                  >
                    <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-carnation-400">
                      {service.title}
                    </h2>
                    <p className="text-slate-200 font-bold text-lg sm:text-xl">
                      {service.price}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      id: 5,
      name: "Facials",
      code: "facials",
      details:
        "Facials involve a variety of skin treatments including: Steam, exfoliation, extraction, moisturizing, massage, and masks. We can customize our facials according to your skin needs and concerns. Facials help to get rid of oils, dirt, toxins, and bacteria and remove clogged pores, which cause blackheads, whiteheads and acne breakouts. Your skin will look firmer and more radiant. * This quick deep cleansing treatment is ideal for oily and impure skin, great removing dead cells and blackheads.",
      data: (
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {facialsData?.map((service, index) => (
              <div
                key={index}
                className="bg-slate-900 rounded-lg shadow-xl p-6 transition-transform transform hover:scale-105"
              >
                <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-carnation-400">
                  {service.title}
                </h2>
                <p className="text-lg sm:text-xl font-bold text-slate-200">
                  {service.time}
                </p>
                <p className="text-xl sm:text-2xl font-bold text-slate-200">
                  Price: {service.price}
                </p>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      id: 6,
      name: "Facial Skin Treatment",
      code: "facial-skin-treatment",
      details:
        "here are many different types of skin problems. In our spa, we will focus on skin problems with different treatments that are available in market such as micro-needling, hydra-facial, micro-dermabrasion, and derma-planing to help clients needs.",
      data: (
        <div className="container mx-auto p-4">
          {facialSkinTreatment?.map((item, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-3xl sm:text-4xl font-bold text-center mb-6">
                {item?.title}
              </h2>
              <div className="text-md sm:text-lg xl:text-xl text-slate-600 max-w-xl mx-auto mb-6">
                {item?.description || ""}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {item?.details.map((detail, idx) => (
                  <div
                    key={idx}
                    className="bg-slate-900 rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105"
                  >
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4 pb-2 text-carnation-400 border-b-2 border-carnation-400 text-center">
                      {detail?.title}
                    </h3>
                    <p className="text-slate-100 mb-4 text-lg sm:text-xl">
                      Price: {detail?.regularPrice}
                    </p>
                    <p className="text-gray-400">{detail?.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      id: 7,
      name: "Microblading",
      code: "microblading",
      details: "",
      data: (
        <div className="container mx-auto p-4">
          {microbladingData.map((treatment, index) => (
            <div key={index} className="mb-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {treatment.details.map((detail, idx) => (
                  <div
                    key={idx}
                    className="bg-slate-900 rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105"
                  >
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4 pb-2 text-carnation-400 border-b-2 border-carnation-400 text-center">
                      {detail.name}
                    </h3>
                    <p className="text-slate-100 mb-4 text-2xl sm:text-3xl">
                      {detail.price}
                    </p>
                    <p className="text-gray-400">{detail.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      id: 8,
      name: "Relaxation Massage",
      code: "relaxation-massage",
      details: "",
      data: (
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {relaxationMassage?.map((service, index) => (
              <div
                key={index}
                className="bg-slate-900 rounded-lg shadow-xl p-6 transition-transform transform hover:scale-105"
              >
                <h2 className="text-xl sm:text-2xl font-semibold mb-2 text-carnation-400">
                  {service.title}
                </h2>
                <p className="text-lg sm:text-xl font-bold text-slate-200">
                  {service.price}
                </p>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      id: 9,
      name: "Spa Packages",
      code: "spa-packages",
      details: "",
      data: (
        <div className="container mx-auto p-4">
          <div className="mb-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {spaPackageData?.map((detail, idx) => (
                <div
                  key={idx}
                  className="bg-slate-900 rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105"
                >
                  <h3 className="text-xl sm:text-2xl font-semibold mb-4 pb-2 text-carnation-400 border-b-2 border-carnation-400 text-center">
                    {detail.name}
                  </h3>
                  <p className="text-slate-100 mb-4 text-2xl sm:text-3xl">
                    {detail.price}
                  </p>
                  <p className="text-gray-400">{detail.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 10,
      name: "Laser Hair Removal",
      code: "laser-hair-removal",
      details:
        "Laser hair removal works by targeting the root of your unwanted hair through a series of treatments. This procedure is absolutely safe and does not damage the surrounding areas of skin. Laser hair removal is one of most popular, safe and effective aesthetic treatments available today. Laser hair removal completely eliminates the mess, discomfort of waxing and tweezing. It is one of most widely performed aesthetic procedures that helps to remove unwanted hair from almost any part of your body. By precisely directing thermal energy to your hair, the follicles’ production cells are destroyed, preventing further growth.",
      data: (
        <div className="container mx-auto p-4">
          {laserHairRemovalData?.map((item, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-3xl sm:text-4xl font-bold text-center mb-6">
                {item?.title}
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {item?.details.map((detail, idx) => (
                  <div
                    key={idx}
                    className="bg-slate-900 rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105"
                  >
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4 pb-2 text-carnation-400 border-b-2 border-carnation-400 text-center">
                      {detail?.title}
                    </h3>
                    <p className="text-slate-100 mb-4 text-lg sm:text-xl">
                      Regular Price: {detail?.regularPrice}
                    </p>
                    <p className="text-slate-100 mb-4 text-lg sm:text-xl">
                      Sale Price: {detail?.salePrice}
                    </p>
                    <p className="text-gray-400">{detail?.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
    },
  ];
  const [selectedCategoryId, setSelectedCategoryId] = useState("hair-service");
  const [detailsData, setDetailsData] = useState({});

  useEffect(() => {
    const data = categoryData.find(
      (category) => category?.code === selectedCategoryId
    );
    if (data) {
      setDetailsData(data);
    }
  }, [selectedCategoryId]);

  return (
    <div className="relative">
      <div
        className={classNames(
          "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden mb-10",
          "after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0 after:bg-gradient-to-r after:from-carnation-900/80 after:from-10% after:via-carnation-900/80 after:via-50%"
        )}
      >
        <Image
          src={
            "https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?q=80&w=1911&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt={""}
          width={"100%"}
          height={"100%"}
          effect={"blur"}
          className={" object-cover"}
        />
        <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-10 lg:bottom:20 xl:bottom-40 z-10 font-bold">
          Services
        </h4>
      </div>
      <div className="relative px-6 py-5 my-3 ">
        <ServiceList
          categoryData={categoryData}
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
        />
        <div className="relative"></div>
      </div>
      <section className="relative overflow-hidden w-full py-20 bg-gray-100">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-10">
            <div className="space-y-4 text-center">
              <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-carnation-700">
                {detailsData?.name || ""}
              </h2>
              <div className="text-md xl:text-xl text-slate-600 max-w-xl mx-auto">
                {detailsData?.details || ""}
              </div>
            </div>
            <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto mt-14"></div>
          </div>
        </div>
        {detailsData?.data}
      </section>
      <div className="relative px-5 py-5 my-5 serviceEndbanner">
        <div className="bg-carnation-900/75 px-5 py-16 rounded-lg">
          <div className="text-white text-7xl capitalize w-[90%] mx-auto mb-5 mt-10 font-bold text-center">
            Love the Skin You're In
          </div>
          <div className="text-white text-3xl capitalize w-[90%] mx-auto mb-8 text-center">
            Find out more about our services and rates.
          </div>
          <div className="flex justify-center">
            <Button
              buttonLabel={"Contact Us"}
              buttonLabelClasses={"!text-sm"}
              buttonClasses={
                "!px-4 !py-3 sm:!px-6 sm:!py-4 lg:!px-8 lg:!py-5 xl:!px-10 xl:!py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-black"
              }
              // buttonIcon={"fa-solid fa-phone-volume"}
              // buttonIconPosition={"left"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
