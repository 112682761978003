import React from "react";
import Image from "../components/elements/Image";
import { classNames } from "../helpers/classNames";
import { Link } from "react-router-dom";

const DefaultFooter = ({ data }) => {
  return (
    <>
      <footer className="relative bg-victoria pt-8">
        <div className="relative py-16 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="flex flex-col items-center gap-6">
            {/* Logo */}
            <div className="max-w-[200px] h-16">
              <Image
                src={
                  "//images.squarespace-cdn.com/content/v1/5e29ca2a630fb46e59749dcb/1579818899281-LK0MSVQHAPQ5KJ9X2C3N/Upkeep_White_nobackground_Wordmark-02.png?format=1500w"
                }
                alt={"Logo"}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-contain"}
              />
            </div>
            {/* Contact Information */}
            <div className="text-center text-xs xl:text-sm text-white">
              621 CAMERON HEIGHTS DRIVE NW, EDMONTON, AB, T6M 0L9 | 780.443.6162
              | INFO@UPKEEPSALON.COM
            </div>
            {/* Social Media */}
            {data.socialMenu?.length > 0 && (
              <div className="flex items-center justify-center gap-2">
                {data.socialMenu?.map((item) => (
                  <a
                    key={item._id}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center w-8 h-8 bg-carnation-500 rounded-md text-white border border-carnation-500 transition-all duration-200 hover:text-carnation-500 hover:bg-white"
                  >
                    <i className={classNames("fa-brands fa-fw", item.icon)}></i>
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="border-t border-slate-300 mt-10 w-full">
          <div className="text-center text-xs text-white py-4">
            {data.copyright}
          </div>
        </div>
      </footer>
    </>
  );
};

export default DefaultFooter;
