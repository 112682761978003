import React from "react";

const Blog = () => {
  return (
    <div>
      Blog
      <div>Blog</div>
      <div>Blog</div>
      <div>Blog</div>
      <div>Blog</div>
      <div>Blog</div>
      <div>Blog</div>
      <div>Blog</div>
      <div>Blog</div>
    </div>
  );
};

export default Blog;
