import React from "react";

const BlogDetails = () => {
  return (
    <div>
      BlogDetails
      <div>BlogDetails</div>
      <div>BlogDetails</div>
      <div>BlogDetails</div>
      <div>BlogDetails</div>
      <div>BlogDetails</div>
      <div>BlogDetails</div>
      <div>BlogDetails</div>
    </div>
  );
};

export default BlogDetails;
