import React from "react";
import Image from "../elements/Image";
import DesignButton from "../extra/DesignButton";

const ServiceCard = () => {
  const services = [
    {
      id: 1,
      image: "https://img.freepik.com/free-photo/woman-relaxing-spa_329181-13152.jpg?w=996&t=st=1721065838~exp=1721066438~hmac=afdabcaf7abccce81d2cb72db31ab1f987751be3aa4a64dfab3758e3ede15772",
    },
    {
      id: 2,
      image: "https://miro.medium.com/v2/resize:fit:1400/1*0hXI7Y-T3n4yr2ak9cT__A.jpeg",
    },
    {
      id: 3,
      image: "https://images.ulta.com/is/image/Ulta/wk40_BSA-UB-gives-back_m",
    },
  ];

  return (
    <section className="relative overflow-hidden w-full py-20 bg-gray-100">
      <div className="relative w-full px-4 sm:px-6 md:px-8 lg:px-12 xl:px-16 mx-auto max-w-screen-xl">
        <div className="space-y-10">
          <div className="text-center space-y-4">
            <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-carnation-700">
              Services
            </h2>
            <p className="text-md xl:text-base text-slate-600 max-w-3xl mx-auto">
              Our salon and spa provides the best services in Edmonton using the
              latest technologies to perform a full range of Skin Treatments,
              Laser Hair Removal, Hydra-Facial, Micro-needling, Derma-planning,
              Chemical Peels, Body Treatments, Microdermabrasion, Manicures,
              Pedicures, Gel Nails, Massages, and all Hair Services.
            </p>
            <div className="flex items-center justify-center mt-10">
              <DesignButton
                buttonClasses={"!px-5"}
                buttonHasLink={true}
                buttonLink={"/"}
                buttonLabel={"Service Menu"}
                buttonEffect={"filled"}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service) => (
              <div
                key={service.id}
                className="p-4 border rounded-lg shadow-lg group hover:shadow-xl transition-shadow duration-300"
              >
                <div className="relative overflow-hidden rounded-lg">
                  <Image
                    src={service.image}
                    alt={`Service ${service.id}`}
                    layout="responsive"
                    width={400}
                    height={300}
                    className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-110"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceCard;
